import React from 'react';
import { Button } from '@mui/material';
import buttonStyles from '../../../stylesheets/components/buttons.module.scss';
import classNames from 'classnames';

interface PortalButtonProps {
  id?: string;
  dataId?: string;
  hidden?: boolean;
  alternate?: boolean;
  extraClasses?: string[];
  dataTestClass?: string;
  disabled?: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const PortalButton = ({
  id,
  dataId,
  hidden,
  alternate,
  extraClasses = [],
  dataTestClass,
  disabled,
  onClick,
  children,
}: PortalButtonProps) => {
  const extraClassesObject = Object.fromEntries(
    extraClasses.map((className) => [className, true])
  );

  return (
    <Button
      id={id}
      data-id={dataId}
      className={classNames(
        buttonStyles.button,
        {
          [`${buttonStyles.alternate}`]: alternate,
          [`${buttonStyles.hidden}`]: hidden,
        },
        extraClassesObject
      )}
      data-test-class={dataTestClass}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default PortalButton;
